import Awesome from "@components/DashboardHome/Pages/Awesome/Awesome";

export const RootRoute = "/";
export const RootRoute301 = "/index.html";

export const PublicRoutes = {
  search: "search",
  freelancers: "search/prestataires",
  freelancersByCountry: "search/prestataires/country/:country",
  freelancersByJob: "search/prestataires/job/:job",
  freelancersBySkill: "search/prestataires/skill/:skill",
  freelancersByLanguage: "search/prestataires/language/:language",
  freelancersByRate: "search/prestataires/rate/:max",
  products: "search/products",
  productsBySkill: "search/products/skill/:skill",
  productsByLanguage: "search/products/language/:language",
  trainings: "search/trainings",
  trainingsBySkill: "search/trainings/skill/:skill",
  trainingsByLanguage: "search/trainings/language/:language",

  projects: "search/projects",
  projectsByCategory: "search/projects/category/:category",
  projectsByApplications: "search/projects/applications/:min/:max",
  projectsBySkill: "search/projects/skill/:skill",
  projectsByLocation: "search/projects/location/:location",
  projectsByLanguage: "search/projects/language/:language",
 
  contrats: "search/contrats",
  contratsByCategory: "search/contrats/category/:category",
  contratsByApplications: "search/contrats/applications/:min/:max",
  contratsBySkill: "search/contrats/skill/:skill",
  contratsByLocation: "search/contrats/location/:location",
  contratsByLanguage: "search/contrats/language/:language",
  
  marketplacehpubuntu: "marketplaceubuntu",
  marketplacehpdebian: "marketplacedebian",
  marketplacehpredhat: "marketplaceredhat",
  marketplacehpmysql: "marketplacemysql",
  marketplacehppostgres: "marketplacepostgres",
  marketplacehpmongodb: "marketplacemongodb",
  design: "designer",
  devback: "developpeurs-backend",
  devfront: "developpeurs-web",
  devops: "devops",
  devseo: "developpeurs-seo",
  devwp: "developpeurs-wordpress",
  devshopify: "developpeurs-shopify",
  devmobile: "developpeurs-mobile",
  sitewp: "missions-wordpress",
  projetwp: "Projets-wp",
  siteapi: "missions-api",
  projetapi: "Projets-api",
  sitemobile: "missions-mobile",
  projetmobile: "Projets-mobile",
  sitetests: "missions-testeurs",
  projettesteurs: "Projets-testeurs",
  siteback: "missions-backend",
  projetback: "Projets-back",
  sitefront: "missions-web",
  projetfront: "Projets-web",
  siteaws: "missions-aws",
  projetsaws: "Projets-aws",
  siteshopify: "missions-shopify",
  projetshopify: "Projets-shopify",
  siteseo: "missions-seo",
  projetseo: "Projets-seo",
  demandeundevis: "svc/app-as-service",
  monitoringELK: "formations/monitoring-site-web-elk/:chapter",
  awesomephp: "awesome-frameworks/php",
  awesome: "formations/awesome-frameworks/:chapter",
  siteWeb: "formations/creer-site-web-gratuit/:chapter",
  plateformFreelance: "plateform-Freelance-2025/:chapter",
  applicationMobile: "formations/creer-une-application-mobile/:chapter",
  shareProduct: "svc/demand-share-product",
  createCustomProduct: "svc/infra-as-service",
  git: "formations/git/:chapter",
  gitlab: "formations/gitlab/:chapter",

};

export const DashboardCompanyRoutes = {
  jobopenings: "jobopenings",
  projects: "projects",
  profil: "profil",
  chat: "dashboardCompany",
  profils: "profils",
  composeyourteam: "composeyourteam",
  trainingsession: "trainingsession",
  appDetails: "detailsAppEntreprise/:id",
  payments: "payments",
  appspurchased: "appspurchased",
  products: "/products",
  videoTrainingDetails: "/detailsVideoTrainingEntreprise/:id",
  sessionTrainingDetails: "/detailsSessionTrainingEntreprise/:id",
  purchasedservices: "servicespurchased",
  purchasedservicesDetails: "/detailsPurchasedApplicationEntreprise/:id",
  purchasedservicesSessionTrainingDetails:
    "/detailsPurchasedSessionTrainingEntreprise/:id",
  purchasedservicesVideoTrainingDetails:
    "/detailsPurchasedVideoTrainingEntreprise/:id",
  createSessionTrainingRequest: "sessionTraining/create",
  sessionTrainingRequest: "sessionTrainingRequest",
  trainingSessionRequest: "trainingsessionRequests",
  joinus: "about-us",
  profileFreelances: "profilsCandidates",
  company: "company",
};

export const DashboardFreelancerRoutes = {
  projects: "projects",
  jobopenings: "jobopenings",
  profilCandidat: "profilcandidat",
  chat: "dashboardCandidat",
  producer: "producer",
  wallet: "/wallet",
  myproduct: "/myproduct/",
  contracts: "contracts"
};

export const DashboardRecruterRoutes = {
  chat: "dashboardRecruter",
  projects: "projects",
  itGalaxyContactor: "itgalaxycontactor",
  itGalaxyRecruitment: "itgalaxyrecruitment",
  recruitmentDash: "recruitmentDash",
  shareCandidat: "shareCandidat/:display",
  shareJob: "shareJob",
  contracts: "contracts",
  myoffers: "myoffers"

};

export const DashboardAccountingRoutes = {
  chat: "dashboardAccounting",
  homepageAccounting: "hpAccounting",
  signupAccounting: "signupAccounting",
  myWorkingFolder: "myworkingfolder",
};

export const TrainingRoutes = {
  videosTrainings: "videosTrainings",
};

export const AuthRoutes = {
  login: "/login",
  logout: "/logout",
  signup: "/signup",
  verifyemail: "verify-email",
  resetpassword: "reset-password",
  checkyouremailplz: "checkyouremailplz",
  dashboard: "dashboard",
  UserAgreement: "useragreement",
  PrivacyPolicy: "privacypolicy",
  TermsOfService: "termsofservice",
  ChangePassword: "changepassword",
  signup_freelancer: "/signup/freelancer",
  signup_company: "/signup/company",
  forget_password: "/forget-password",
  composeyourteam: "/composeyourteam",
};

export const ItGalaxyMarketplaceRoutes = {
  marketplace: "marketplace",
  itgalaxyProductDetails: "itgalaxyProductDetails/:token",
  itgalaxyProductPurchased: "itgalaxyProductPurchased/:token",
  productPurchased: "productPurchased/:token",
  server: "createProductServer",
  database: "createProductDatabase",
  github: "createProductGithub",
  products: "myproducts",

};

export const ErrorRoutes = {
  page404: "/404",
  page500: "/500",
  error: "/*",
};

export const BlogRoutes = {
  blog: "blog",
  blogPost: "blog/:slug",
};
